import { useEffect, useState } from 'react'

import styles from './CommonSettings.module.css'
import { AppDispatch } from '../../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import {
  fetchAddSettings,
  fetchSettings,
} from '../../../redux/reducers/settings'
import {
  fetchAddCities,
  fetchCities,
  fetchDeleteCities,
} from '../../../redux/reducers/cities'
import DeleteButton from '../../../components/buttons/actions-button/DeleteButton'
import {
  fetchAddDictionaryExpenses,
  fetchDictionaryDeleteExpenses,
  fetchDictionaryExpenses,
} from '../../../redux/reducers/dictionary'
import { dictionarySelector } from '../../../redux/selectors/dictionary-expenses-selector'
import { settingsSelector } from '../../../redux/selectors/settings-selector'
import EditButton from '../../../components/buttons/actions-button/EditButton'
import {
  fetchAddCountries,
  fetchCountries,
  fetchDeleteCountries,
} from '../../../redux/reducers/countries'
import { countriesSelector } from '../../../redux/selectors/countries-selector'
import { citiesSelector } from '../../../redux/selectors/cities-selector'
import Spinner from '../../../components/spinner/Spinner'
import DeleteModal from '../../../components/modal/DeleteModal'
import { onlyNumberRegularCheck } from '../../../utils/onlyNumberRegularCheck'

function CommonSettings() {
  const dispatch = useDispatch<AppDispatch>()
  const [addExpensesVisible, setAddExpensesVisible] = useState(false)
  const [addCountriesVisible, setAddCountriesVisible] = useState(false)
  const [addCitiesVisible, setAddCitiesVisible] = useState(false)

  const {
    dictionary,
    isPendingGetDictionary,
    isPendingAddDictionary,
    isPendingDeleteDictionary,
  } = useSelector(dictionarySelector)
  const { settings, isPending } = useSelector(settingsSelector)
  const {
    countries,
    isPendingGetCountry,
    isPendingAddCountry,
    isPendingDeleteCountry,
  } = useSelector(countriesSelector)

  const {
    cities,
    isPendingGetCities,
    isPendingAddCities,
    isPendingDeleteCities,
  } = useSelector(citiesSelector)

  useEffect(() => {
    dispatch(fetchSettings())
    dispatch(fetchCountries())
    dispatch(fetchCities())
    dispatch(fetchDictionaryExpenses())
  }, [])

  const [settingsValue, setSettingsValue] = useState(settings?.[0].value || 0)

  useEffect(() => {
    setSettingsValue(settings?.[0].value as number)
  }, [settings])
  function ChangeItem({ setIsVisibleModal, children, title }: any) {
    const handleClose = () => {
      setIsVisibleModal(false)
    }

    return (
      <div style={{ display: 'block' }} className={styles.win}>
        <div className={styles.winContainer}>
          <div className={styles.winContent}>
            <div onClick={handleClose} className={styles.close}>
              ×
            </div>
            <div className={styles.winContentFulltext}>
              <h3
                style={{ textAlign: 'center' }}
                className={classNames(`mb-4 ${styles.h3}}`)}
              >
                {title}
              </h3>
              {children}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const [currentExpenses, setCurrentExpenses] = useState<any>(null)
  const [deleteExpenses, setDeleteExpenses] = useState<boolean>(false)
  const handleDeleteExpenses = () => {
    dispatch(fetchDictionaryDeleteExpenses(currentExpenses?.item?.slug)).then(
      (res) => {
        //@ts-ignore
        if (!res.error) {
          setDeleteExpenses(false)
          setCurrentExpenses(null)
        }
      }
    )
  }
  const ItemExpenses = (item: any) => {
    return (
      <div className="row mb-1 align-items-center">
        <div className="col pr-1">
          <input value={item?.item?.name} type="text" />
        </div>
        <div className="col-auto pl-0 pr-0">
          <EditButton
            onClick={() => {
              setAddExpensesVisible(true)
              setCurrentExpenses(item)
            }}
          />
          <DeleteButton
            onClick={() => {
              setDeleteExpenses(true)
              setCurrentExpenses(item)
            }}
          />
        </div>
      </div>
    )
  }

  const [currentCountry, setCurrentCountry] = useState<any>(null)
  const [deleteCountries, setDeleteCountries] = useState<boolean>(false)

  const handleDeleteCountries = () => {
    dispatch(fetchDeleteCountries(currentCountry?.item?.iso)).then((res) => {
      //@ts-ignore
      if (!res.error) {
        setDeleteCountries(false)
        setCurrentCountry(null)
      }
    })
  }
  const ItemCountry = (item: any) => {
    return (
      <>
        <div className="row mb-1 align-items-center">
          <div className="col pr-1">
            <input value={item?.item?.name} type="text" />
          </div>

          <div className="col-auto pl-0 pr-0">
            <EditButton
              onClick={() => {
                setAddCountriesVisible(true)
                setCurrentCountry(item)
              }}
            />
            <DeleteButton
              onClick={() => {
                setDeleteCountries(true)
                setCurrentCountry(item)
              }}
            />
          </div>
        </div>
      </>
    )
  }

  const AddItemCountries = (item: any) => {
    const [name, setName] = useState(currentCountry?.item?.name || '')
    //@ts-ignore
    const [iso, setIso] = useState(currentCountry?.item?.iso || '')

    const handleAddCountry = () => {
      if (currentCountry) {
        dispatch(
          fetchAddCountries({ name, iso, prevValue: currentCountry?.item?.iso })
        ).then((res) => {
          //@ts-ignore
          if (!res.error) {
            setAddCountriesVisible(false)
          }
        })
      } else {
        dispatch(fetchAddCountries({ name, iso })).then((res) => {
          //@ts-ignore
          if (!res.error) {
            setAddCountriesVisible(false)
          }
        })
      }
    }

    useEffect(() => {
      return () => {
        setCurrentCountry(null)
      }
    }, [])

    return (
      <>
        <div className="row mb-1 align-items-center">
          <div className="col pr-1">
            Страна*
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              className="mb-4"
            />
            Обозначение(BY, RU, KZ...)*
            <input
              value={iso}
              onChange={(e) => setIso(e.target.value)}
              type="text"
            />
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-between mt-4 mb-2">
          <button
            className={classNames(styles.btn, styles.btnSmall)}
            onClick={handleAddCountry}
            disabled={!name || !iso}
          >
            Сохранить
          </button>
          <div
            className={classNames(
              styles.btn,
              styles.btnSmall2,
              styles.btnBlueOutline,
              styles.cancelBtn
            )}
            onClick={() => setAddCountriesVisible(false)}
          >
            Отмена
          </div>
        </div>
      </>
    )
  }

  const handleDeleteCities = () => {
    dispatch(
      fetchDeleteCities({
        country: currentCities.item.country.iso,
        id: currentCities.item.id,
      })
    ).then((res) => {
      //@ts-ignore
      if (!res.error) {
        setDeleteCities(false)

        setCurrentCities(null)
      }
    })
  }
  const [currentCities, setCurrentCities] = useState<any>(null)
  const [deleteCities, setDeleteCities] = useState<boolean>(false)

  const ItemCities = (item: any) => {
    return (
      <div className="row mb-1 align-items-center">
        <div className="col pr-1">
          <input value={item?.item?.name} type="text" />
        </div>
        <div className="col-auto pl-0 pr-0">
          <EditButton
            onClick={() => {
              setAddCitiesVisible(true)
              setCurrentCities(item)
            }}
          />
          <DeleteButton
            onClick={() => {
              setDeleteCities(true)
              setCurrentCities(item)
            }}
          />
        </div>
      </div>
    )
  }

  const AddItemCities = (item: any) => {
    //@ts-ignore
    const [name, setName] = useState(currentCities?.item?.name || '')
    //@ts-ignore
    const [country, setCountry] = useState(
      currentCities?.item?.country?.iso || ''
    )

    useEffect(() => {
      return () => {
        setCurrentCities(null)
      }
    }, [])

    const handleAddCity = () => {
      if (currentCities) {
        dispatch(
          fetchAddCities({ name, country, prevValue: currentCities?.item?.id })
        ).then((res) => {
          //@ts-ignore
          if (!res.error) {
            setAddCitiesVisible(false)
          }
        })
      } else {
        dispatch(fetchAddCities({ name, country })).then((res) => {
          //@ts-ignore
          if (!res.error) {
            setAddCitiesVisible(false)
          }
        })
      }
    }

    return (
      <>
        <div className="row mb-1 align-items-center">
          <div className="col pr-1">
            Город*
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              className="mb-4"
            />
            Страна*
            <select
              value={country}
              onChange={(e) => {
                setCountry(e.target.value)
              }}
            >
              <option value="" key={undefined}>
                — Выберите —
              </option>
              {countries?.map((item) => (
                <option key={item.iso} value={item.iso}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-between mt-4 mb-2">
          <button
            className={classNames(styles.btn, styles.btnSmall)}
            onClick={handleAddCity}
            disabled={!name || !country}
          >
            Сохранить
          </button>
          <div
            className={classNames(
              styles.btn,
              styles.btnSmall2,
              styles.btnBlueOutline,
              styles.cancelBtn
            )}
            onClick={() => setAddCitiesVisible(false)}
          >
            Отмена
          </div>
        </div>
      </>
    )
  }

  const AddItemExpenses = (item: any) => {
    const [name, setName] = useState(currentExpenses?.item?.name || '')
    const [slug, setSlug] = useState(currentExpenses?.item?.slug || '')

    const handleAddExpenses = () => {
      if (currentExpenses) {
        dispatch(
          fetchAddDictionaryExpenses({
            name,
            slug,
            prevValue: currentExpenses?.item?.slug,
          })
        ).then((res) => {
          //@ts-ignore
          if (!res.error) {
            setAddExpensesVisible(false)
            setCurrentExpenses(null)
          }
        })
      } else {
        dispatch(fetchAddDictionaryExpenses({ name, slug })).then((res) => {
          //@ts-ignore
          if (!res.error) {
            setAddExpensesVisible(false)
            setCurrentExpenses(null)
          }
        })
      }
    }
    return (
      <>
        <div className="row mb-1 align-items-center">
          <div className="col pr-1 pt-4">
            Наименование*
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              className="mb-4"
            />
            Обозначение(англ)*
            <input
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
              type="text"
            />
          </div>
        </div>

        <div className="d-flex flex-wrap justify-content-between mt-4 mb-2">
          <button
            className={classNames(styles.btn, styles.btnSmall)}
            onClick={handleAddExpenses}
            disabled={!name || !slug}
          >
            Сохранить
          </button>
          <div
            className={classNames(
              styles.btn,
              styles.btnSmall2,
              styles.btnBlueOutline,
              styles.cancelBtn
            )}
            onClick={() => {
              setCurrentExpenses(null)
              setAddExpensesVisible(false)
            }}
          >
            Отмена
          </div>
        </div>
      </>
    )
  }

  if (
    isPendingGetCities ||
    isPendingAddCities ||
    isPendingDeleteCities ||
    isPendingGetCountry ||
    isPendingAddCountry ||
    isPendingDeleteCountry ||
    isPendingGetDictionary ||
    isPendingAddDictionary ||
    isPendingDeleteDictionary ||
    isPending
  ) {
    return <Spinner />
  }

  const handleSettings = (e: any) => {
    setSettingsValue(e.target.value.replace(',', '.'))
    dispatch(
      fetchAddSettings({ value: String(e.target.value.replace(',', '.')) })
    )
  }

  return (
    <div>
      <div className={classNames(styles.blockWhite)}>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 mt-1 mb-1">
            <div className={classNames(styles.bgGray)}>
              <div className="mb-2">
                <b>Страны</b>
                {countries?.map((item) => <ItemCountry item={item} />)}

                <div
                  className={classNames(
                    styles.btn,
                    styles.btnSmall2,
                    styles.btnBlue
                  )}
                  onClick={() => setAddCountriesVisible(true)}
                >
                  + Добавить
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 mt-1 mb-1">
            <div className={classNames(styles.bgGray)}>
              <div className="mb-2">
                <b>Города</b>
                {cities?.map((item) => <ItemCities item={item} />)}
                <div
                  className={classNames(
                    styles.btn,
                    styles.btnSmall2,
                    styles.btnBlue
                  )}
                  onClick={() => setAddCitiesVisible(true)}
                >
                  + Добавить
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 mt-1 mb-1">
            <div className={classNames(styles.bgGray)}>
              <div className="row">
                <div className="col">
                  <b>Командировочные</b>, EUR:
                </div>
                <div className="col">
                  <input
                    value={settingsValue}
                    onChange={(e) => {
                      if (onlyNumberRegularCheck(e)) {
                        handleSettings(e)
                      }
                    }}
                    className="d-inline-block"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 mt-1 mb-1">
            <div className={classNames(styles.bgGray)}>
              <div className="mb-2">
                <b>Список расходов</b>
              </div>

              {dictionary?.map((item) => <ItemExpenses item={item} />)}

              <div
                className={classNames(
                  styles.btn,
                  styles.btnSmall2,
                  styles.btnBlue
                )}
                onClick={() => setAddExpensesVisible(true)}
              >
                + Добавить
              </div>
            </div>
          </div>
        </div>
      </div>
      {addCitiesVisible && (
        <ChangeItem setIsVisibleModal={setAddCitiesVisible} title={'Город'}>
          <AddItemCities />
        </ChangeItem>
      )}
      {addCountriesVisible && (
        <ChangeItem setIsVisibleModal={setAddCountriesVisible} title={'Страна'}>
          <AddItemCountries />
        </ChangeItem>
      )}

      {addExpensesVisible && (
        <ChangeItem
          setIsVisibleModal={setAddExpensesVisible}
          title={'Вид расхода'}
        >
          <AddItemExpenses />
        </ChangeItem>
      )}

      {deleteCities && (
        <DeleteModal
          title="Город"
          onConfirm={handleDeleteCities}
          onCancel={() => {
            setDeleteCities(false)
            setCurrentCities(null)
          }}
        />
      )}

      {deleteCountries && (
        <DeleteModal
          title="Страна"
          onConfirm={handleDeleteCountries}
          onCancel={() => {
            setCurrentCountry(null)
            setDeleteCountries(false)
          }}
        />
      )}

      {deleteExpenses && (
        <DeleteModal
          title="Вид расхода"
          onConfirm={handleDeleteExpenses}
          onCancel={() => {
            setCurrentExpenses(null)
            setDeleteExpenses(false)
          }}
        />
      )}
    </div>
  )
}

export default CommonSettings

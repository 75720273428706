import React, { RefObject, useEffect, useRef, useState } from 'react'

import styles from '../Documents.module.css'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../redux/store'
import {
  Contract,
  fetchContracts,
  setCurrentContract,
  setIsVisibleDeleteModalContract,
  setIsVisibleModalContract,
} from '../../../redux/reducers/documents/contracts'
import { contractsSelector } from '../../../redux/selectors/documents/contracts-selector'
import { useOutsideClick } from '../../../hooks/useOutsideClick'
import EditButton from '../../../components/buttons/actions-button/EditButton'
import DeleteButton from '../../../components/buttons/actions-button/DeleteButton'
import { formatDate, isLessThanAMonth } from '../../../utils/date'
import {
  fetchClient,
  fetchClients,
  setIsVisibleModalClient,
} from '../../../redux/reducers/clients'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { registerLocale } from 'react-datepicker'
import ru from 'date-fns/locale/ru'

registerLocale('ru', ru)
interface DropDownProps {
  value: { id: string | number; name: string }
  items: Array<{ id: string | number; name: string }>
  refDropdown: RefObject<HTMLDivElement>
  handleButtonClick: () => void
  isSelectMenu: boolean
  handleChangeItem: (item: { id: string | number; name: string }) => void
  isVisibleAddBlock?: boolean
}

const COUNTRIES = [
  { id: '', name: 'Все страны' },
  { id: 'BY', name: 'Беларусь' },
  { id: 'RU', name: 'Россия' },
]

const STATUSES = [
  { id: '', name: 'Все статусы' },
  { id: 1, name: 'Активен' },
  { id: 0, name: 'Закрыт' },
]

const DropDown: React.FC<DropDownProps> = (props) => {
  const {
    value,
    items,
    refDropdown,
    handleButtonClick,
    isSelectMenu,
    handleChangeItem,
    isVisibleAddBlock,
  } = props
  return (
    <div
      className={
        isVisibleAddBlock
          ? 'd-none d-sm-block col-sm-6 col-md-6 col-lg-3 col-xl-3'
          : 'col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3'
      }
    >
      <div
        ref={refDropdown}
        className={`${styles.dropdown} d-block`}
        style={{ textAlign: 'left' }}
      >
        <div
          onClick={handleButtonClick}
          className={classNames(
            styles.btn,
            styles.btnSmall,
            styles.btnInput,
            styles.btnDropdown,
            isSelectMenu && styles.btnDropdownActive
          )}
        >
          {value.name}
        </div>
        <div
          className={classNames(
            styles.dropdownContent,
            styles.dropdownSelect,
            isSelectMenu && styles.dropdownContentActive
          )}
        >
          {items.map((item) => (
            <div onClick={() => handleChangeItem(item)} key={item.id}>
              {item.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const Filter = ({ setUpdatedContracts }: { setUpdatedContracts: any }) => {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined)
  const [endDate, setEndDate] = useState<Date | undefined>(undefined)
  const [isOpen, setIsOpen] = useState(false)

  const { contracts } = useSelector(contractsSelector)

  const handleChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates
    setStartDate(start || undefined)
    setEndDate(end || undefined)
  }

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  //COUNTRY
  const [country, setCountry] = useState<{ id: string | number; name: string }>(
    COUNTRIES[0]
  )
  const [isSelectMenuCountry, setIsSelectMenuCountry] = useState(false)
  const refCountry = useOutsideClick(() => {
    setIsSelectMenuCountry(false)
  })
  const handleSelectChangeCountry = (country: {
    id: string | number
    name: string
  }) => {
    setCountry(country)

    setIsSelectMenuCountry(false)
  }
  const handleButtonClickCountry = () => {
    setIsSelectMenuCountry((prev) => !prev)
  }

  //STATUS
  const [status, setStatus] = useState<{ id: string | number; name: string }>(
    STATUSES[1]
  )
  const [isSelectMenuStatus, setIsSelectMenuStatus] = useState(false)

  const refStatus = useOutsideClick(() => {
    setIsSelectMenuStatus(false)
  })
  const handleSelectChangeStatus = (status: {
    id: string | number
    name: string
  }) => {
    setStatus(status)

    setIsSelectMenuStatus(false)
  }
  const handleButtonClickStatus = () => {
    setIsSelectMenuStatus((prev) => !prev)
  }

  const getClients = contracts.map((item) => ({
    id: item.client.id,
    name: item.client.name,
  }))
  const [clients, setClients] = useState<{ id: string | number; name: string }>(
    { id: '', name: 'Все клиенты' }
  )
  const [isSelectMenuClients, setIsSelectMenuClients] = useState(false)

  const refClients = useOutsideClick(() => {
    setIsSelectMenuClients(false)
  })

  const redData = useOutsideClick(() => {
    setIsOpen(false)
  })

  const handleSelectChangeClients = (clients: {
    id: string | number
    name: string
  }) => {
    setClients(clients)

    setIsSelectMenuClients(false)
  }
  const handleButtonClickClients = () => {
    setIsSelectMenuClients((prev) => !prev)
  }

  useEffect(() => {
    let filtered = contracts

    // CLIENT ID
    if (!!clients.id) {
      filtered = filtered.filter(
        (contract) => contract.client.id === clients.id
      )
      setUpdatedContracts(filtered)
    } else {
      setUpdatedContracts(filtered)
    }

    // DATE
    if (startDate && endDate) {
      const start = new Date(startDate)
      start.setHours(0, 0, 0, 0)

      const end = new Date(endDate)
      end.setHours(23, 59, 59, 999)

      filtered = filtered.filter((contract) => {
        const contractDate = new Date(contract.date)
        contractDate.setHours(0, 0, 0, 0)
        return contractDate >= start && contractDate <= end
      })

      setUpdatedContracts(filtered)
    } else {
      setUpdatedContracts(filtered)
    }

    // COYNTRY
    if (!!country.id) {
      filtered = filtered.filter(
        (contract) => contract.client.country.iso === country.id
      )
      setUpdatedContracts(filtered)
    } else {
      setUpdatedContracts(filtered)
    }

    // STATUS
    if (status.id !== '') {
      console.log('status', status)
      filtered = filtered.filter(
        (contract) => Number(contract.active) === status.id
      )
      console.log('filtered', filtered)
      setUpdatedContracts(filtered)
    } else {
      setUpdatedContracts(filtered)
    }
  }, [
    clients.id,
    contracts,
    country.id,
    endDate,
    setUpdatedContracts,
    startDate,
    status,
  ])

  useEffect(() => {
    setUpdatedContracts([])
  }, [])
  return (
    <div className={classNames(styles.filterForm, styles.filterDocs, 'mb-3')}>
      <div
        className={classNames(
          styles.tableFilterPanel,
          styles.small,
          'row',
          'align-items-end'
        )}
      >
        <div
          className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"
          ref={redData}
        >
          <div id="docsfilter_date_input_wrapper" className="datePickerInput">
            <input
              className={classNames(styles.calendarInput, styles.date)}
              type="text"
              onClick={handleClick}
              value={
                startDate && endDate
                  ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
                  : 'Выберите диапазон дат'
              }
              readOnly
            />
            {isOpen && (
              <div className={styles.datePickerWrapper}>
                <DatePicker
                  popperClassName={styles.datePickerWrapper1}
                  calendarClassName={styles.datePickerWrapper1}
                  wrapperClassName={styles.datePickerWrapper1}
                  className={styles.datePickerWrapper1}
                  selected={startDate}
                  onChange={handleChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  monthsShown={2}
                  showPopperArrow={false}
                  locale="ru"
                  inline
                />
              </div>
            )}
          </div>
        </div>
        <DropDown
          value={country}
          items={COUNTRIES}
          refDropdown={refCountry}
          handleButtonClick={handleButtonClickCountry}
          isSelectMenu={isSelectMenuCountry}
          handleChangeItem={handleSelectChangeCountry}
        />
        <DropDown
          value={status}
          items={STATUSES}
          refDropdown={refStatus}
          handleButtonClick={handleButtonClickStatus}
          isSelectMenu={isSelectMenuStatus}
          handleChangeItem={handleSelectChangeStatus}
          isVisibleAddBlock={true}
        />
        <DropDown
          value={clients}
          items={[{ id: '', name: 'Все клиенты' }, ...getClients]}
          refDropdown={refClients}
          handleButtonClick={handleButtonClickClients}
          isSelectMenu={isSelectMenuClients}
          handleChangeItem={handleSelectChangeClients}
        />
      </div>
    </div>
  )
}

//@ts-ignore
const Contracts = ({ contracts }: Contract[]) => {
  const dispatch = useDispatch<AppDispatch>()

  // ----------------------------------------------
  const handleDeleteContract = (item: Contract) => {
    dispatch(setCurrentContract(item))
    dispatch(setIsVisibleDeleteModalContract(true))
  }

  const handleEditContract = (item: Contract) => {
    dispatch(setCurrentContract(item))
    dispatch(setIsVisibleModalContract(true))
  }

  const handleEditClient = async (item: Contract) => {
    await dispatch(fetchClient(item.client.id))
    dispatch(setIsVisibleModalClient(true))
  }

  return (
    <>
      <div
        className={classNames(
          styles.docItem,
          styles.small2,
          'd-none d-lg-block pl-2 pr-2',
          'mt-4 mb-2',
          'mb-3'
        )}
      >
        <div className="row">
          <div className="col-lg-1 col-xl-1">
            <span className={classNames(styles.colorGray)}>Номер</span>
          </div>
          <div className="col-lg-4 col-xl-4">
            <span className={classNames(styles.colorGray)}>Клиент</span>
          </div>
          <div className="col-lg-2 col-xl-2">
            <span className={classNames(styles.colorGray)}>Дата</span>
          </div>
          <div className="col-lg-2 col-xl-2">
            <span className={classNames(styles.colorGray)}>Действует до</span>
          </div>
          <div className="col-lg-1 col-xl-1">
            <span className={classNames(styles.colorGray)}>Статус</span>
          </div>
          <div className="col-lg-2 col-xl-2"></div>
        </div>
      </div>

      {contracts?.map((item: Contract) => {
        return (
          <div
            className={classNames(
              styles.bgWhite,
              styles.border10,
              styles.bxShadow,
              styles.small2,
              styles.docItem,
              'pl-2',
              'pr-2',
              'mb-3'
            )}
          >
            <div className={classNames(styles.row, 'row')}>
              <div
                className={classNames(
                  styles.colorGray,
                  'order-4 col-5 order-sm-1 col-sm-3 order-md-1 col-md-3 order-lg-1 col-lg-1 col-xl-1 pt-1 pb-1'
                )}
              >
                {item.number}
              </div>

              <div
                className={classNames(
                  styles.docClient,
                  'order-1 col-6 order-sm-2 col-sm-9 order-md-2 col-md-7 order-lg-2 col-lg-4 col-xl-4 pt-1 pb-1'
                )}
                onClick={() => handleEditClient(item)}
              >
                <a className={styles.client}>
                  <div className={styles.clientLogo}>
                    <img
                      className={
                        !!item?.client?.logo?.original ? '' : styles.opacity02
                      }
                      src={
                        item?.client?.logo?.original ||
                        'img/clients/icon-flag.svg'
                      }
                    />
                  </div>
                  <div className="name ellipsis-2">
                    <b>{item.client.name}</b>
                  </div>
                </a>
              </div>
              <div
                className={classNames(
                  styles.colorGray,
                  'order-5 col-3 order-sm-4 col-sm-2 order-md-5 col-md-2 order-lg-3 col-lg-2 col-xl-2 pt-1 pb-1'
                )}
              >
                {formatDate(item.date)}
              </div>
              <div
                className={classNames(
                  styles.colorGray,
                  isLessThanAMonth(item.expires_at) && styles.colorRed,
                  'order-6 col-3 order-sm-5 col-sm-2 order-md-6 col-md-2 order-lg-4 col-lg-2 col-xl-2 pt-1 pb-1'
                )}
              >
                до {formatDate(item.expires_at)}
              </div>
              <div
                className={classNames(
                  styles.docStatus,
                  'order-2 col-3 order-sm-3 col-sm-3 order-md-4 col-md-3 order-lg-5 col-lg-1 col-xl-1 pt-1 pb-1'
                )}
              >
                {item.active ? (
                  <>
                    <i
                      className={`${styles.icon} ${styles.iconVerified2} ${styles.icon16}`}
                    />{' '}
                    <span>Активен</span>
                  </>
                ) : (
                  <span>Закрыт</span>
                )}
                <br />
              </div>
              <div
                className="doc-buttons order-3 col-3 order-sm-6 col-sm-5 order-md-3 col-md-2 order-lg-6 col-lg-2 col-xl-2 pt-1 pb-1"
                style={{ textAlign: 'right' }}
              >
                <EditButton onClick={() => handleEditContract(item)} />
                <DeleteButton onClick={() => handleDeleteContract(item)} />
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

function DocumentsContracts() {
  const dispatch = useDispatch<AppDispatch>()

  const [updatedContracts, setUpdatedContracts] = useState<[] | Contract[]>([])

  const { contracts } = useSelector(contractsSelector)

  useEffect(() => {
    const filtered = contracts.filter(
      (contract) => Number(contract.active) === 1
    )
    setUpdatedContracts(filtered)
  }, [contracts])

  useEffect(() => {
    dispatch(fetchContracts())
    dispatch(fetchClients())
  }, [])

  return (
    <div className="tabs-content">
      <div className="row">
        <div className="col-12 offset-md-1 col-md-10 offset-lg-0 col-lg-12  offset-xl-1 col-xl-10">
          <div className="tab-content" data-content="tab_docs_1">
            <Filter setUpdatedContracts={setUpdatedContracts} />

            {!!updatedContracts.length ? (
              //@ts-ignore
              <Contracts contracts={updatedContracts} />
            ) : (
              <div
                style={{ textAlign: 'center' }}
                className="pt-5 pb-5 pl-5 pr-5 bg-white border-10"
              >
                <p>
                  <i className="icon icon-70 opacity-03 icon-reports"></i>
                </p>
                <div className="font-big color-gray">
                  Таких документов за выбранный период пока нет
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocumentsContracts

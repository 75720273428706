import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import { URL } from '../../constants/url'
import axiosInstance from '../../utils/axiosInstance'
import { AxiosResponse } from 'axios'
import { RootState } from '../store'
import { toast } from 'react-toastify'
import { MyKnownError } from '../types/common'

interface Car {
  can_create_report: boolean
  id: number
  active: boolean
  name: string
  number: string
  vin: string
  insurance_end_rb: string
  insurance_end_rf: string
  state_inspection_end: string
  cargo_volume: number
  cargo_weight: number
  image: {
    lg: string
    md: string
    original: string
    sm: string
  }
}

export interface CarsState {
  currentCar: Car | null
  currentTab: number
  cars: Array<Car>
  isVisibleModalCar: boolean
  isVisibleDeleteModalCar: boolean
  isPendingGetCars: boolean
  // РЕДАКТИРОВАНИЕ и ДОБАВЛЕНИЕ
  isPendingAddCar: boolean
  isFulfilledAddCar: boolean
  isRejectAddCar: boolean
}

const initialState: CarsState = {
  currentCar: null,
  currentTab: 1,
  isVisibleModalCar: false,
  isVisibleDeleteModalCar: false,
  cars: [],
  isPendingGetCars: true,
  isPendingAddCar: false,
  isFulfilledAddCar: false,
  isRejectAddCar: false,
}

// FOR OWNERS
export const fetchCars = createAsyncThunk(
  'cars/fetchCars',
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.get(`/${URL.cars}`)

      if (response.statusText === 'OK') {
        return response
      }
      throw new Error()
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

export const fetchCar = createAsyncThunk(
  'cars/fetchCar',
  async (carId: number, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.get(
        `/${URL.cars}/${carId}`
      )

      if (response.statusText === 'OK') {
        return response
      }
      throw new Error()
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

type FetchEditCar = {
  selectedImage?: any
  id?: number
  currentCompanyId?: string | number
  carName: any
  vin: any
  number: any
  volume: any
  weight: any
  insuranceEndRb: any
  insuranceEndRf: any
  stateInspectionEnd: any
  isActive: any
}

export const fetchEditCar = createAsyncThunk(
  'cars/fetchEditCar',
  async (
    {
      carName,
      vin,
      number,
      volume,
      weight,
      insuranceEndRb,
      insuranceEndRf,
      stateInspectionEnd,
      isActive,
      id = undefined,
      selectedImage,
    }: FetchEditCar,
    thunkAPI
  ) => {
    const formData = new FormData()
    formData.append('name', carName)
    formData.append('active', isActive ? '1' : '0')
    formData.append('vin', vin)
    formData.append('number', number)
    formData.append('cargo_volume', volume)
    formData.append('cargo_weight', weight)
    formData.append('insurance_end_rb', insuranceEndRb || '')
    formData.append('insurance_end_rf', insuranceEndRf || '')
    if (stateInspectionEnd)
      formData.append('state_inspection_end', stateInspectionEnd)
    formData.append('name', carName)

    if (selectedImage) {
      formData.append('image', selectedImage, selectedImage.name)
    }

    if (id) {
      formData.set('_method', 'put')
    }

    try {
      const response = await axiosInstance.post(
        `/${URL.cars}${id ? `/${id}` : ''}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

export const fetchAddCarForAdmin = createAsyncThunk(
  'cars/fetchAddCarForAdmin',
  async (
    { carName, selectedImage, isActive, currentCompanyId }: FetchEditCar,
    thunkAPI
  ) => {
    const formData = new FormData()

    formData.append('name', carName)
    formData.append('active', isActive ? '1' : '0')

    if (selectedImage) {
      formData.append('image', selectedImage, selectedImage.name)
    }

    try {
      const response = await axiosInstance.post(
        `/${URL.company}/${currentCompanyId}/car`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

export const fetchDeleteCar = createAsyncThunk(
  'cars/fetchDeleteCar',
  async (_, thunkAPI) => {
    const {
      cars: { currentCar },
    } = thunkAPI.getState() as RootState

    try {
      const response = await axiosInstance.delete(
        `/${URL.cars}/${currentCar?.id}`
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

export const carsSlice = createSlice({
  name: 'cars',
  initialState,
  reducers: {
    setCurrentCar: (state, action) => {
      return { ...state, currentCar: action.payload }
    },
    setCurrentTab: (state, action) => {
      return { ...state, currentTab: action.payload }
    },
    //@ts-ignore

    setIsVisibleModalCar: (state, action: { payload: boolean }) => {
      return {
        ...state,

        isVisibleModalCar: action.payload,
        //@ts-ignore
        currentCar:
          typeof action.payload === 'number'
            ? //@ts-ignore
              current(state).cars.find((item) => item.id === action.payload)
            : action.payload
              ? state.currentCar
              : null,
      }
    },

    setIsVisibleDeleteModalCar: (state, action: { payload: boolean }) => ({
      ...state,
      isVisibleDeleteModalCar: action.payload,
    }),
  },
  extraReducers: (builder) => {
    //FOR OWNERS
    builder.addCase(fetchCars.pending, (state) => {
      return { ...state, isPendingGetCars: true }
    })
    builder.addCase(fetchCars.fulfilled, (state, action) => {
      return { ...state, cars: action.payload.data, isPendingGetCars: false }
    })
    builder.addCase(fetchCars.rejected, (state, action) => {
      toast.error(action.payload as string)

      return { ...state, isPendingGetCars: false }
    })
    builder.addCase(fetchCar.fulfilled, (state, action) => {
      state.currentCar = action.payload.data
    })

    builder.addCase(fetchEditCar.pending, (state) => {
      return {
        ...state,
        isPendingAddCar: true,
        isFulfilledAddCar: false,
        isRejectAddCar: false,
      }
    })
    builder.addCase(fetchEditCar.fulfilled, (state, action) => {
      toast.success('Сохранено!')

      const isCarExist = current(state).cars.find(
        (item) => item.id === action.payload.id
      )

      if (isCarExist) {
        return {
          ...state,
          isPendingAddCar: false,
          isFulfilledAddCar: true,
          isRejectAddCar: false,
          isVisibleModalCar: false,
          cars: state.cars.map((item) =>
            item.id === action.payload.id ? action.payload : item
          ),
        }
      }
      return {
        ...state,
        isPendingAddCar: false,
        isFulfilledAddCar: true,
        isRejectAddCar: false,
        cars: [action.payload, ...state.cars],
        isVisibleModalCar: false,
      }
    })
    builder.addCase(fetchEditCar.rejected, (state, action) => {
      toast.error(action.payload as string)

      return {
        ...state,
        isPendingAddCar: false,
        isFulfilledAddCar: false,
        isRejectAddCar: true,
      }
    })

    // FOR ADMIN
    builder.addCase(fetchAddCarForAdmin.pending, (state) => {
      return state
    })
    builder.addCase(fetchAddCarForAdmin.fulfilled, (state, action) => {
      toast.success('Сохранено!')

      return {
        ...state,

        cars: [action.payload, ...state.cars],
        isVisibleModalCar: false,
      }
    })
    builder.addCase(fetchAddCarForAdmin.rejected, (state, action) => {
      toast.error(action.payload as string)

      return state
    })

    builder.addCase(fetchDeleteCar.pending, (state) => {
      return state
    })
    builder.addCase(fetchDeleteCar.fulfilled, (state, action) => {
      toast.success('Удалено!')

      return {
        ...state,
        isVisibleDeleteModalCar: false,
        currentCar: null,
        cars: state.cars.filter((car) => car.id !== state?.currentCar?.id),
      }
    })
    builder.addCase(fetchDeleteCar.rejected, (state, action) => {
      toast.error(action.payload as string)

      return state
    })
  },
})

export const {
  setCurrentCar,
  setCurrentTab,
  setIsVisibleModalCar,
  setIsVisibleDeleteModalCar,
} = carsSlice.actions

export default carsSlice.reducer

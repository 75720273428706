import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../../redux/store'
import styles from './AddContractsModal.module.css'
import classNames from 'classnames'

import { useEffect, useState } from 'react'
import { getRole } from '../../../../utils/getRole'
import { useClearBodyNoScrollClass } from '../../../../hooks/use-clear-body-no-scroll-slass'
import {
  fetchAddContract,
  setIsVisibleModalContract,
} from '../../../../redux/reducers/documents/contracts'
import { fetchClients } from '../../../../redux/reducers/clients'
import { clientsSelector } from '../../../../redux/selectors/clients-selector'
import { onlyNumberRegularCheck } from '../../../../utils/onlyNumberRegularCheck'
import { contractsSelector } from '../../../../redux/selectors/documents/contracts-selector'
import LoadingPage from '../../../loading/LoadingPage'

const STATUS = [
  {
    name: 'Активен',
    value: true,
  },
  {
    name: 'Закрыт',
    value: false,
  },
]
const { isOwner } = getRole()

function AddContractsModal() {
  const dispatch = useDispatch<AppDispatch>()
  const { currentContract } = useSelector(contractsSelector)
  const { clients } = useSelector(clientsSelector)
  useClearBodyNoScrollClass()

  useEffect(() => {
    dispatch(fetchClients())
  }, [])

  const [clientId, setClientId] = useState('')
  const [date, setDate] = useState('')
  const [expiresAt, setExpiresAt] = useState('')
  const [number, setNumber] = useState('')
  const [amount, setAmount] = useState('')

  const [currency, setCurrency] = useState('BYN')
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (currentContract) {
      const {
        date,
        active,
        expires_at,
        number,
        client: { id },
        amount,
        currency,
      } = currentContract
      setClientId(String(id))
      setDate(date)
      setExpiresAt(expires_at)
      setNumber(number)
      setAmount(amount)
      setCurrency(currency)
      setIsActive(active)
    }
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setCurrency(value)
  }

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value === 'true'
    setIsActive(selectedValue)
    setErrors((prevErrors) => ({ ...prevErrors, isActive: false }))
  }

  const handleSelectChangeClient = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedId = event.target.value
    setClientId(selectedId)
    setErrors((prevErrors) => ({ ...prevErrors, clientId: false }))
  }

  const [errors, setErrors] = useState({
    clientId: false,
    isActive: false,
    date: false,
    expiresAt: false,
    number: false,
  })

  const checkValidation = () => {
    const newErrors = {
      clientId: !clientId,
      isActive: !isActive,
      date: !date,
      expiresAt: !expiresAt,
      number: !number,
    }

    setErrors(newErrors)
  }

  const handleSaveContract = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const newErrors = {
      clientId: !clientId,
      isActive: isActive === null || isActive === undefined,
      date: !date,
      expiresAt: !expiresAt,
      number: !number,
    }

    setErrors(newErrors)

    const hasErrors = Object.values(newErrors).some((error) => error)

    if (!hasErrors) {
      if (currentContract?.id) {
        dispatch(
          fetchAddContract({
            id: currentContract?.id,
            isActive,
            clientId,
            date,
            expiresAt,
            number,
            currency,
            amount,
          })
        )
      } else {
        dispatch(
          fetchAddContract({
            isActive,
            clientId,
            date,
            expiresAt,
            number,
            currency,
            amount,
          })
        )
      }
    }
  }

  return (
    <div
      style={{ display: 'block' }}
      className={styles.win}
      id="win_company_edit"
    >
      <div className={styles.winContainer}>
        <div className={styles.winContent}>
          <div
            onClick={() => dispatch(setIsVisibleModalContract(false))}
            className={styles.close}
          >
            ×
          </div>
          <div className={styles.winContentFulltext}>
            <h3
              style={{ textAlign: 'center' }}
              className={classNames(styles.h3, 'mb-3')}
            >
              {currentContract ? 'Редактировать договор' : 'Добавить договор'}
            </h3>

            <div
              className={classNames(styles.colorRed, styles.small, 'mb-3')}
              style={{ textAlign: 'center' }}
            >
              <br />
              {/* Близок предел суммы договора
              245,000 из 300,000 ₽ */}
            </div>

            <form onSubmit={handleSaveContract}>
              <div className="row mb-3">
                <div className="col-12 col-sm-8 col-xl-8 mb-3">
                  <small className={classNames(styles.small2, 'd-block mb-1')}>
                    Клиент
                  </small>
                  <select
                    className={errors.clientId ? styles.isError : ''}
                    value={clientId}
                    onChange={handleSelectChangeClient}
                  >
                    <option value="">Выберите клиента</option>
                    {clients.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.entity ? item.entity_name : item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
                  <small className={classNames(styles.small2, 'd-block mb-1')}>
                    Статус
                  </small>

                  <select
                    onChange={handleSelectChange}
                    value={String(isActive)}
                    className={errors.isActive ? styles.isError : ''}
                  >
                    {STATUS.map((item) => (
                      <option key={item.name} value={String(item.value)}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
                  <small className={classNames(styles.small2, 'd-block mb-1')}>
                    Номер
                  </small>
                  <input
                    className={errors.number ? styles.isError : ''}
                    value={number}
                    onChange={(e) => {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        number: false,
                      }))

                      setNumber(e.target.value)
                    }}
                    required
                    type="text"
                    placeholder="Номер договора"
                  />
                </div>

                <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
                  <small className={classNames(styles.small2, 'd-block mb-1')}>
                    Дата
                  </small>
                  <div className="calendar" id="calendar_contract_date">
                    <input
                      // min={insuranceEndRb || undefined}
                      className={classNames(
                        styles.calendarInput,
                        styles.date,
                        errors.date ? styles.isError : ''
                      )}
                      type="date"
                      value={date}
                      onChange={(e) => {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          date: false,
                        }))
                        setDate(e.target.value)
                      }}
                      pattern="\d{4}-\d{2}-\d{2}"
                    />
                  </div>
                </div>

                <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
                  <small className={classNames(styles.small2, 'd-block mb-1')}>
                    Действует до
                  </small>
                  <div className="calendar" id="calendar_contract_date_to">
                    <input
                      // min={insuranceEndRb || undefined}
                      className={classNames(
                        styles.calendarInput,
                        styles.date,
                        errors.date ? styles.isError : ''
                      )}
                      type="date"
                      value={expiresAt}
                      onChange={(e) => {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          expiresAt: false,
                        }))

                        setExpiresAt(e.target.value)
                      }}
                      pattern="\d{4}-\d{2}-\d{2}"
                    />
                  </div>
                </div>

                <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
                  <small className={classNames(styles.small2, 'd-block mb-1')}>
                    Сумма договора
                  </small>
                  <input
                    value={amount}
                    onChange={(e) => {
                      if (onlyNumberRegularCheck(e)) {
                        setAmount(e.target.value.replace(',', '.'))
                      }
                    }}
                    type="text"
                    placeholder="Сумма договора"
                  />
                  <div
                    className={classNames(
                      styles.radioTabs,
                      styles.small2,
                      'd-flex flex-wrap justify-content-start'
                    )}
                    style={{ textAlign: 'left' }}
                  >
                    <div className="mt-1">
                      <input
                        name="currency"
                        id="currency_radio4"
                        type="radio"
                        value="EUR"
                        checked={currency === 'EUR'}
                        onChange={handleInputChange}
                      />
                      <label className="ml-2 mr-0" htmlFor="currency_radio4">
                        EUR
                      </label>
                    </div>
                    <div className="mt-1">
                      <input
                        name="currency"
                        id="currency_radio5"
                        type="radio"
                        value="RUB"
                        checked={currency === 'RUB'}
                        onChange={handleInputChange}
                      />
                      <label className="ml-2 mr-0" htmlFor="currency_radio5">
                        RUB
                      </label>
                    </div>
                    <div className="mt-1">
                      <input
                        name="currency"
                        id="currency_radio6"
                        type="radio"
                        value="BYN"
                        checked={currency === 'BYN'}
                        onChange={handleInputChange}
                      />
                      <label className="ml-2 mr-0" htmlFor="currency_radio6">
                        BYN
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div
                  className="col-12 col-sm-6 mt-1 mb-1"
                  style={{ textAlign: 'left' }}
                >
                  <button
                    onClick={checkValidation}
                    className={classNames(
                      'd-block',
                      'd-sm-inline-block',
                      styles.btn
                    )}
                  >
                    <i className={`${styles.icon} ${styles.iconCheckWhite}`} />{' '}
                    {!currentContract ? 'Создать' : 'Сохранить'}
                  </button>
                </div>
                <div
                  className="col-12 col-sm-6 mt-1 mb-1"
                  style={{ textAlign: 'right' }}
                >
                  <div
                    onClick={() => dispatch(setIsVisibleModalContract(false))}
                    className={classNames(
                      'd-block',
                      'd-sm-inline-block',
                      styles.btn,
                      styles.btnSmall,
                      styles.btnBlueOutline
                    )}
                  >
                    Отмена
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddContractsModal
